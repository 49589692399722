<template>
    <div>
        <div v-if="pageName === 'index'">
            <jy-query ref="form" labelWidth="100" class="query_area" size="small">
                <jy-query-item label="产废企业">
                    <el-select v-model="form.unitId">
                        <el-option
                            v-for="u in rUnitList"
                            :key="u.unitId"
                            :value="u.unitId"
                            :label="u.cname"
                        ></el-option>
                    </el-select>
                </jy-query-item>
                <jy-query-item label="所属行业">
                    <el-select v-model="form.industry">
                        <el-option
                            v-for="p in industryList"
                            :key="p.iValue"
                            :value="p.iValue"
                            :label="p.iValue"
                        ></el-option>
                    </el-select>
                </jy-query-item>
                <jy-query-item label="所属行政区域">
                    <div @click="selectRegion">
                        <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                    </div>
                </jy-query-item>
                <jy-query-item label="固废类别">
                    <el-select v-model="form.wasteTId">
                        <el-option
                            v-for="t in wasteTypes"
                            :key="t.wasteTId"
                            :label="t.cName"
                            :value="t.wasteTId"
                        ></el-option>
                    </el-select>
                </jy-query-item>
                <jy-query-item label="所属机构" v-if="visible">
                    <div @click="selectInstitutions">
                        <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                    </div>
                </jy-query-item>
                <jy-query-item label="月份">
                    <el-date-picker
                        value-format="yyyy-MM"
                        v-model="form.grossWT"
                        type="month"
                        placeholder="选择月"
                        :clearable="false"
                    ></el-date-picker>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                    <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
                    <!-- <el-row>
                    <export-excel
                        fileName="委托订单"
                        url=""
                        fileType=".xls"
                        size="small"
                    >
                    </export-excel>
                    </el-row>-->
                </template>
            </jy-query>
            <div>搜索结果合计：记录{{ total }}条，产废量净重{{ stat.sum }}kg，上一年同期清运重量{{ stat.sumPre }}kg，与上一年同期变化{{ formatPercent(this.stat.percent) }}%</div>
            <div class="jy-table" v-loading="loading">
                <jy-table :data="dataList">
                    <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                    <jy-table-column label="产废企业" min-width="140">
                        <template v-slot="scope">{{ scope.row.unitInfo.cName }}</template>
                    </jy-table-column>
                    <jy-table-column label="所属行业" min-width="120">
                        <template v-slot="scope">{{ scope.row.unitInfo.industry }}</template>
                    </jy-table-column>
                    <jy-table-column label="企业所属行政地区" min-width="150">
                        <template v-slot="scope">{{ scope.row.unitInfo.regionNa }}</template>
                    </jy-table-column>
                    <jy-table-column label="固废类别" min-width="120">
                        <template v-slot="scope">{{ scope.row.wasteTNa }}</template>
                    </jy-table-column>
                    <jy-table-column label="所属机构" v-if="visible" min-width="140">
                        <template v-slot="scope">{{ scope.row.orgNa }}</template>
                    </jy-table-column>
                    <jy-table-column label="统计时间" min-width="120">{{ this.form.grossWT }}</jy-table-column>
                    <jy-table-column :label="`净重（${ unit }）`" sortable prop="number" min-width="120">
                        <template v-slot="scope">{{ scope.row.sum }}</template>
                    </jy-table-column>
                    <jy-table-column :label="`上一年同期(${ unit })`" min-width="150">
                        <template v-slot="scope">{{ scope.row.sumPre }}</template>
                    </jy-table-column>
                    <jy-table-column label="与上一年同期变化" sortable prop="number" min-width="180">
                        <template v-slot="scope">{{ formatPercent(scope.row.percent) }}%</template>
                    </jy-table-column>
                    <jy-operate :list="operationList"></jy-operate>
                </jy-table>
                <jy-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :total="total"
                ></jy-pagination>
            </div>

            <region-tree ref="region" @select="addRegion"></region-tree>

            <institutions-tree
                refresh
                :showType="showType"
                :expended="expended"
                :modal="false"
                ref="institutions"
                @addInstitutions="addInstitutions"
                title="选择所属机构"
            ></institutions-tree>
        </div>

        <Detail
            :data-list="detailDataList"
            :back="switchPageIndex"
            :visible="pageName === 'detail'"
            :month="form.grossWT"
            :c-name="cName"
        ></Detail>
    </div>
</template>

<script>
// import ExportExcel from "@/components/pages/admin/common/export";
import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import Detail from "./detail.vue"

export default {
    async created() {
        await this.initData();
        this.getList();
    },
    methods: {
        switchPageIndex() {
            this.pageName = 'index';
        },
        switchPageDetail(data) {
            this
                .getDetailDataList(data)
                .finally(() => {
                    this.pageName = 'detail';
                });
        },
        async initData() {
            this.initTime();
            this.getIndustryList();
            this.getRUnitList();
            await this.getWasteTypeList();
        },
        initTime() {
            const d = new Date();
            const year = d.getFullYear();
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            this.form.grossWT = `${year}-${month}`;
        },
        oncheck() {
            this.pageIndex = 1;
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$http
                .post("/stat/unit/wastes/monthStat", {
                    ...this.form,
                    pageSize: this.pageSize,
                    pageIndex: this.pageIndex
                })
                .then(({ detail }) => {
                    this.dataList = detail.data;
                    this.total = detail.total;
                    this.unit = this.wasteTypes.find(each => each.wasteTId === this.form.wasteTId).unit;
                    this.stat = detail.extraData
                })
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getWasteTypeList() {
            return this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypes = res.detail;
                    this.form.wasteTId = this.wasteTypes[0].wasteTId;
                    this.unit = this.wasteTypes[0].unit;
                });
        },
        async getRUnitList() {
            return this.$http
                .post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "0",
                    contractWorkT: this.$util.parseTime(new Date(), "yyyy-MM-dd hh:mm:ss")
                })
                .then(res => {
                    this.rUnitList = res.detail;
                });
        },
        async getIndustryList() {
            this.$http
                .post("/dict/getDictValuesByDictId", "dictindustry")
                .then(res => {
                    this.industryList = res.detail;
                });
        },
        getDetailDataList(data) {
            this.cName = data.unitInfo.cName;
            return this.$http
                .post('/stat/unit/wastes/dayStatForMonth', {
                    unitId: data.unitInfo.unitId,
                    wasteTId: data.wasteTId,
                    grossWT: this.form.grossWT,
                })
                .then(res => {
                    this.detailDataList = res.detail.filter(v => v.data);
                })
        },
        add() {
            this.$emit("switch");
        },
        // 重置
        async resetForm(formName) {
            Object.keys(this.form).map(key => { this.form[key] = null; });
            this.$refs[formName].resetFields();
            await this.initData();
            this.oncheck();
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getList();
        },
        selectRegion() {
            this.$refs.region.init();
        },
        addRegion(data) {
            this.form.regionId = data.regionId;
            this.form.regionNa = data.regionNa;
        },
        addInstitutions(data) {
            this.form.orgId = data.orgId;
            this.form.orgNa = data.orgNa;
        },
        // 选择机构树
        selectInstitutions() {
            this.showType = [1];
            this.expended = false;
            this.$refs.institutions.init({ flag: "org" });
        },
        formatPercent(percent) {
            if (percent === undefined) {
                return 0
            }
            const res = percent * 100;
            if (res > 0) {
                return '+' + res.toString();
            }
            return res.toString();
        }
    },
    computed: {
        visible() {
            const uType = this.$store.state.info.uType;

            if (typeof uType === 'number' && uType === 2) {
                return false;
            }

            return true;
        }
    },
    data() {
        return {
            pageName: 'index',
            loading: false,
            dataList: [],
            total: 0,
            showType: [0, 1, 2],
            expended: true,

            statisticsInfo: {},
            unit: '',

            form: {
                unitId: null,
                industry: null,
                orgNa: null,
                orgId: null,
                grossWT: null,
                wasteTId: null,
                regionId: null,
                regionNa: null,
            },
            stat: {},

            rUnitList: [],
            industryList: [],
            wasteTypes: [],

            pageIndex: 1,
            pageSize: 10,

            operationList: [{
                name: '产废明细',
                fun: this.switchPageDetail,
                isShow: () => {
                    return true;
                }
            }],

            detailDataList: [],
            cName: null,
        };
    },
    components: {
        // ExportExcel,
        RegionTree,
        institutionsTree,
        Detail
    }
};
</script>
<style scoped>
.query_area {
    margin-top: 10px;
}
</style>
